
import { mapState } from 'vuex'

export default {
  middleware: 'auth',
  auth: 'guest',
  async asyncData({ store }) {
    await store.dispatch('global/loadLoginPageSettings')
    await store.dispatch('global/loadResetPasswordPageSettings')
  },

  computed: {
    ...mapState('global', ['loginPageSettings']),
    showBackLink() {
      return !/^\/login\/?$/.test(this.$route.path)
    },

    title() {
      if (/^\/login\/?$/.test(this.$route.path)) {
        return 'login'
      } else if (this.$route.path === '/login/forgot-password') {
        return 'forgot-password'
      }

      return null
    },
  },
}
